<template>
  <div class="">
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="10"
      layout="prev, pager, next, total,jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: ["total"],
  data() {
    return {
      currentPage: "1",
    };
  },
  methods: {
    changeFenlei(item) {
      this.activeFenlei = item;
      this.$parent.getlist(item);
    },
    changeShengchan(item) {
      this.activeShengchan = item;
      this.$parent.getlist(item);
    },
    handleSizeChange(val) {
      this.$parent.getlist(val);
    },
    handleCurrentChange(val) {
      this.$parent.getlist(val);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../assets/base.less";
</style>
