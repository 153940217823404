<template>
  <div>
    <header>
      <div class="box">
        <div class="banner">
          <div class="my-total">
            <div class="number">{{ Points.cumulativeGetCredit }}</div>
            <div class="top">
              <img
                style="margin-right: 5px"
                src="@/assets/images/myPoints/start.png"
              />
              累计积分总额
            </div>
          </div>
          <div class="su"></div>
          <div class="my-total">
            <div class="number">{{ Points.cumulativeGetCredit }}</div>
            <div class="top">累计获得总额</div>
          </div>
          <div class="my-total">
            <div class="number">{{ Points.cumulativeConsumeCredit }}</div>
            <div class="top">累计消费总额</div>
          </div>
        </div>
      </div>
    </header>
    <div style="padding-top: 32px" class="MyMessage p32">
      <div class="tt df">
        <div class="suxian"></div>
        积分明细
      </div>
      <div class="tabel">
        <el-table
          :cell-style="cellStyle"
          tooltip-effect="light"
          :header-cell-style="{ background: '#F8F8F8' }"
          ref="singleTable"
          :data="tableData"
          highlight-current-row
          style="width: 100%"
        >
          <el-table-column
            align="left"
            show-overflow-tooltip
            property="orderSn"
            label="订单编号"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            align="center"
            property="customerName"
            label="所属客户"
            width="auto"
          >
          </el-table-column>

          <el-table-column
            align="center"
            property="amount"
            label="获得积分"
            width="120"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.type == 300" style="color: #26c487"
                >-{{ scope.row.amount }}
              </span>
              <span v-else style="color: #ffa936">
                +{{ scope.row.amount }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            property="amount"
            label="类型"
            width="120"
          >
            <template slot-scope="scope">
              <span
                :style="
                  scope.row.type == 200
                    ? 'background: #eefbf6;'
                    : scope.row.type == 100
                    ? 'background:#fff8f0;'
                    : ''
                "
                style="padding: 5px 10px; border-radius: 4px"
                :class="
                  scope.row.type == 200
                    ? 'gr'
                    : scope.row.type == 300
                    ? 'gr'
                    : scope.row.type == 100
                    ? 'orange'
                    : ''
                "
              >
                {{
                  scope.row.type == 200
                    ? "赠送"
                    : scope.row.type == 100
                    ? "兑换"
                    : scope.row.type == 300
                    ? "抵扣"
                    : ""
                }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            property="createTime"
            label="到账时间"
            width="200"
          >
          </el-table-column>
          <el-table-column align="right" label="操作" width="80">
            <template slot-scope="scope">
              <div
                v-if="scope.row.type !== 200"
                @click="goDetail(scope.row)"
                style="color: #26c487; font-size: 14px"
                class="cup"
              >
                查看订单
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="total > 10" class="pagenation">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="10"
          layout="prev, pager, next, total,jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mallCreditsDetailList,
  mallCreditStatistical,
} from "@/request/Orders/order";
import Pagination from "@/components/pagination.vue";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      page: 1,
      total: 0,
      Points: {},
      tableData: [
        {
          name: 123,
        },
      ],
    };
  },
  created() {
    this.getList();
    this.getTotalPoint();
  },
  methods: {
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return "color: #333";
    },
    getTotalPoint() {
      mallCreditStatistical().then((res) => {
        if (res.code === 200) {
          this.Points = res.data;
        }
      });
    },
    getList() {
      let creditsDetailListReq = {
        pageNum: this.page,
        pageSize: 10,
      };
      mallCreditsDetailList(creditsDetailListReq).then((res) => {
        if (res.code === 200) {
          this.total = res.data.total;
          this.tableData = res.data.rows;
        }
      });
    },
    goDetail(item) {
      this.$router.push({
        name: "OrderDetail",
        params: { id: item.orderId },
      });
    },
    handleCurrentChange(val) {
      let that = this;
      this.page = val;
      that.getList();
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/base.less";
::v-deep {
  .el-table__cell:nth-child(1) {
    padding-left: 20px;
  }
  .el-table__cell:nth-child(5) {
    padding-right: 20px;
  }

  .el-table thead {
    color: #999999;
    font-weight: 500;
  }
  .el-button {
    height: 32px;
    padding: 7px 18px;
  }
  .el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    transition: all 0.3s;
    line-height: 35px;
  }
  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
}
.gr {
  font-size: 12px;
  font-family: 'regular';
  font-weight: 400;
  color: #26c487;
}
.orange {
  font-size: 12px;
  font-family: 'regular';
  font-weight: 400;
  color: #ffa936;
}
.red {
  font-size: 12px;
  font-family: 'regular';
  font-weight: 400;
  color: #ff1a2e;
}
.su {
  opacity: 0.3;
  margin-right: 55px;
  height: 76px;
  border: 1px solid #ffffff;
}
header {
  padding-bottom: 30px;
  background: #f3f5f7;
  .banner {
    padding-left: 40px;
    display: flex;
    align-items: center;
    width: 100%;
    background: url("../../../assets/images/myPoints/banner.png") no-repeat;
    height: 183px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    .my-total {
      margin-right: 55px;
      .top {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: 'regular';
        font-weight: 400;
        color: #fff5bf;
      }
      .number {
        margin-bottom: 20px;
        text-align: center;
        font-size: 28px;
        font-family: DIN-Bold, DIN;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
}
.pagenation {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
}
.tt {
  font-size: 18px;
  font-family: 'medium';
  font-weight: 500;
  color: #333333;
  line-height: 0px;
}
</style>
