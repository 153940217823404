var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',[_c('div',{staticClass:"box"},[_c('div',{staticClass:"banner"},[_c('div',{staticClass:"my-total"},[_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.Points.cumulativeGetCredit))]),_vm._m(0)]),_c('div',{staticClass:"su"}),_c('div',{staticClass:"my-total"},[_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.Points.cumulativeGetCredit))]),_c('div',{staticClass:"top"},[_vm._v("累计获得总额")])]),_c('div',{staticClass:"my-total"},[_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.Points.cumulativeConsumeCredit))]),_c('div',{staticClass:"top"},[_vm._v("累计消费总额")])])])])]),_c('div',{staticClass:"MyMessage p32",staticStyle:{"padding-top":"32px"}},[_vm._m(1),_c('div',{staticClass:"tabel"},[_c('el-table',{ref:"singleTable",staticStyle:{"width":"100%"},attrs:{"cell-style":_vm.cellStyle,"tooltip-effect":"light","header-cell-style":{ background: '#F8F8F8' },"data":_vm.tableData,"highlight-current-row":""}},[_c('el-table-column',{attrs:{"align":"left","show-overflow-tooltip":"","property":"orderSn","label":"订单编号","width":"auto"}}),_c('el-table-column',{attrs:{"align":"center","property":"customerName","label":"所属客户","width":"auto"}}),_c('el-table-column',{attrs:{"align":"center","property":"amount","label":"获得积分","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type == 300)?_c('span',{staticStyle:{"color":"#26c487"}},[_vm._v("-"+_vm._s(scope.row.amount)+" ")]):_c('span',{staticStyle:{"color":"#ffa936"}},[_vm._v(" +"+_vm._s(scope.row.amount)+" ")])]}}])}),_c('el-table-column',{attrs:{"align":"center","property":"amount","label":"类型","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:scope.row.type == 200
                  ? 'gr'
                  : scope.row.type == 300
                  ? 'gr'
                  : scope.row.type == 100
                  ? 'orange'
                  : '',staticStyle:{"padding":"5px 10px","border-radius":"4px"},style:(scope.row.type == 200
                  ? 'background: #eefbf6;'
                  : scope.row.type == 100
                  ? 'background:#fff8f0;'
                  : '')},[_vm._v(" "+_vm._s(scope.row.type == 200 ? "赠送" : scope.row.type == 100 ? "兑换" : scope.row.type == 300 ? "抵扣" : "")+" ")])]}}])}),_c('el-table-column',{attrs:{"align":"center","property":"createTime","label":"到账时间","width":"200"}}),_c('el-table-column',{attrs:{"align":"right","label":"操作","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type !== 200)?_c('div',{staticClass:"cup",staticStyle:{"color":"#26c487","font-size":"14px"},on:{"click":function($event){return _vm.goDetail(scope.row)}}},[_vm._v(" 查看订单 ")]):_vm._e()]}}])})],1)],1),(_vm.total > 10)?_c('div',{staticClass:"pagenation"},[_c('el-pagination',{attrs:{"background":"","current-page":_vm.page,"page-size":10,"layout":"prev, pager, next, total,jumper","total":_vm.total},on:{"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event}}})],1):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('img',{staticStyle:{"margin-right":"5px"},attrs:{"src":require("@/assets/images/myPoints/start.png")}}),_vm._v(" 累计积分总额 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tt df"},[_c('div',{staticClass:"suxian"}),_vm._v(" 积分明细 ")])
}]

export { render, staticRenderFns }